import React from 'react';
import { Container } from 'react-bootstrap';

const Home = () => {
    return (
        <Container>
            
            <h1>Welcome to the Home Page.</h1>
            <p>This is my Home Page.</p>
            <p> Current Project React Website.</p>
        </Container>
    );
};

export default Home;