import React from 'react';
import { Container } from 'react-bootstrap';

const Certs = () => {
    return (
        <Container>

            <h1>Certs I have Completed.</h1>
            <p>Google IT Support Specialization</p>
            <p>Google Cybersecurity Specialization</p>
        </Container>
    );
};

export default Certs;